import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, startWith, takeUntil, finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { combineLatest, Subject } from 'rxjs';

const REDIRECT_USER_TIME = 3000;

@Component({
  selector: 'email-verified',
  templateUrl: './email-verified.component.html',
  styleUrls: ['./email-verified.component.scss']
})
export class EmailVerifiedComponent implements OnInit, OnDestroy {
  isMobile$ = this.route.data.pipe(
    startWith({ mobile: false }),
    map(data => data.mobile)
  );
  isLoading = true;
  token: string;
  unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sdk: SuzySdkService,
    private auth: AuthenticationService
  ) {}

  ngOnInit() {
    combineLatest([this.auth.ensureUser(), this.route.queryParams])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([user, params]) => {
        //  if user is already verified then redirect to dashboard or to get-verified page
        if (user.phone_verified_utc) {
          this.router.navigate(['/dashboard']);
        } else if (user.email_verified_utc) {
          this.router.navigate(['/auth/get-verified']);
        } else if (params['token']) {
          this.completeEmailVerification(params['token']);
        } else {
          this.router.navigate(['/']);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  completeEmailVerification(token: string) {
    this.sdk.ProtocolRegister.verifyEmailComplete({
      verification_confirm_token: token
    })
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res: any) => {
        if (res.success) {
          this.auth.reloadUser();
          setTimeout(() => {
            this.router.navigate(['/auth/get-verified']);
          }, REDIRECT_USER_TIME);
        } else {
          this.router.navigate(['/link-expired']);
        }
      });
  }
}
