import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import {
  ISignupUser,
  SignupService
} from '../../../../views/anonymous/signup/signup.service';
import { environment } from '../../../../../environments/environment';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { Title } from '@angular/platform-browser';
import { AppInputValidators } from 'apps/crowdtap/src/app/shared/app-input-validators';
import { Subject } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { SmsPromptService } from '../../../../shared/services/sms-prompt.service';

@Component({
  selector: 'get-verified',
  templateUrl: './get-verified.component.html',
  styleUrls: ['./get-verified.component.scss']
})
export class GetVerifiedComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  legalId = environment.legalId;
  user: ISignupUser;
  // Send Phone Form
  sendPhoneForm: UntypedFormGroup;
  isSubmittedPhone = false;
  isValidPhone = false;
  isNewSignupQuestions!: boolean;

  // Send Code Form
  sendCodeForm: UntypedFormGroup;
  isSubmittedCode = false;
  isValidCode = false;

  // General
  isLoading = false; // TODO: Add after all logic defiend.
  isPassVerification = false;
  isFirst = true;
  subject: Subject<any>;
  el_wrapper: HTMLElement;
  skipLinkPathContent: string;
  skipLinkPathFooter: string;

  isChecked: boolean = false;

  isMobile$ = this.route.data.pipe(
    startWith({ mobile: false }),
    map(data => data.mobile)
  );
  isSmsFirstFlowSingUp = false;
  isVerifyEmailFlow = false;

  constructor(
    private fb: UntypedFormBuilder,
    private sdk: SuzySdkService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private signupService: SignupService,
    private titleService: Title,
    private segmentService: SegmentService,
    private snackbarService: SnackbarService,
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private smsPromptService: SmsPromptService
  ) {}

  ngOnInit(): void {
    this.user = this.signupService.getUser();
    this.isSmsFirstFlowSingUp =
      this.launchDarklyService.getCWDT2328MoveSMSQuestionFlag();
    this.isVerifyEmailFlow =
      this.launchDarklyService.getRW74And76AbilityToSignUpWithAMagicLink();
    this.isFirst = this.signupService.isFirstSMSVerifyLoad;
    this.createSendPhoneForm();
    this.createSendCodeForm();
    this.subject = new Subject<any>();
    this.getAvailableOpt();
    this.isNewSignupQuestions =
      this.launchDarklyService.getCWDT2172SignUpQuestionsFlag();
  }

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  setHeadTitle(): any {
    this.titleService.setTitle('Crowdtap® - Confirm your phone');
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }

  createSendPhoneForm(): void {
    this.sendPhoneForm = this.fb.group({
      phone: ['', [Validators.required, AppInputValidators.phoneUS]]
    });
  }

  createSendCodeForm(): void {
    this.sendCodeForm = this.fb.group({
      code: ['', [Validators.required]]
    });
  }

  fieldErrors(name: string): any {
    const control_phone = this.sendPhoneForm.get(name);
    const control_code = this.sendCodeForm.get(name);

    // Send Phone Form Error
    if (control_phone && control_phone.errors) {
      this.isValidPhone = false;

      return control_phone.errors;
    } else if (control_phone && !control_phone.errors) {
      this.isValidPhone = true;
    }

    // Send Code Form Error

    if (control_code && control_code.errors) {
      this.isValidCode = false;

      return control_code.errors;
    } else if (control_code && !control_code.errors) {
      this.isValidCode = true;
    }

    return undefined;
  }

  isPhoneTouched(): boolean {
    return this.sendPhoneForm.get('phone').touched;
  }

  isCodeTouched(): boolean {
    return this.sendCodeForm.get('code').touched;
  }

  onChangePhoneNumber(): void {
    this.sendPhoneForm.get('phone').markAsUntouched();
    this.sendPhoneForm.get('phone').setValue('');
    this.isSubmittedPhone = false;
  }

  onSubmitPhone(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.signupService.setUser({
      ...this.user,
      progressStatus: 95
    });
    this.segmentService.track('SMS Verification Submitted');
    this.sdk.ProtocolRegister.verifyPhoneStart({
      mobile_phone: this.preparePhoneNumber(
        this.sendPhoneForm.controls['phone'].value
      )
    })
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.isLoading = false;
          });
        })
      )
      .subscribe((res: any) => {
        const message = !res.success ? { server: res.message } : {};
        this.sendPhoneForm.get('phone').setErrors(message);
        this.isSubmittedPhone = res.success;
      });
  }

  onHaveCode(): void {
    if (this.isLoading) {
      return;
    }
    this.isSubmittedCode = true;
  }

  onResendCode(): void {
    if (this.isLoading) {
      return;
    }
    this.sendCodeForm.get('code').markAsUntouched();
    this.sendCodeForm.get('code').setValue('');
    this.isSubmittedPhone = false;
  }

  onSubmitCode(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.sdk.ProtocolRegister.verifyPhoneComplete({
      token: this.sendCodeForm.get('code').value
    })
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.isLoading = false;
          });
        })
      )
      .subscribe((res: any) => {
        if (res.success) {
          this.segmentService.identifySMS(
            this.sendPhoneForm.get('phone').value
          );
          this.segmentService.track('SMS Verification Success');
          this.signupService.setUser({
            ...this.user,
            progressStatus: 100
          });
          if (this.isSmsFirstFlowSingUp) {
            this.signupService.setUser({
              ...this.user,
              progressStatus: 45
            });

            if (!this.smsPromptService.isGetNotifiedModalSeen) {
              this.smsPromptService.openModal().result.then(() => {
                this.router.navigate(['/auth/get-to-know-you']);
              });
            } else {
              this.router.navigate(['/auth/get-to-know-you']);
            }
          }
          if (this.isVerifyEmailFlow) {
            this.router.navigate(['/']);
          }
        }
        const message = !res.success ? { server: res.message } : {};
        this.sendCodeForm.get('code').setErrors(message);
        if (!this.smsPromptService.isGetNotifiedModalSeen) {
          this.smsPromptService.openModal().result.then(() => {
            this.auth.reloadUser();
          });
        } else {
          this.auth.reloadUser();
        }
        this.isPassVerification = res.success;
        this.isSubmittedCode = res.success;
      });
  }

  preparePhoneNumber(phone: string): string {
    return `+1${phone.replace(/\D/g, '')}`;
  }

  getAvailableOpt() {
    this.signupService.checkAvailableOpt().subscribe(result => {
      this.isChecked = result.items.length ? false : true;
    });
  }

  checkValue(evt: any) {
    this.isChecked = evt.target.checked;
    const apiCall = this.isChecked
      ? this.signupService.verifyChecked(this.legalId)
      : this.signupService.verifyUnchecked(this.legalId);
    apiCall.subscribe(
      result => {
        this.isChecked = result.message == 'OptedOut' ? false : true;
      },
      error => {
        this.snackbarService.appendComponentToBody(
          `Unable to ${
            this.isChecked ? 'opt-in' : 'opt-out'
          } to SMS, please try again later.`,
          5000,
          'crowdtap-exclamation-circle'
        );
        this.isChecked = !this.isChecked;
      }
    );
  }
}
