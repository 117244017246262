import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  Renderer2
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  AuthenticationService,
  UserProfileService
} from '@suzy/crowdtap/data-access/user';
import { SettingsService } from 'apps/crowdtap/src/app/core/services/settings.service';
import { Observable, Subject } from 'rxjs';
import { ProfileMenuService } from './../../../../shared/components/profile-menu/profile-menu.service';
import {
  SegmentChallengeStage,
  SegmentErrorSource,
  SegmentRewardGoalLocation,
  SegmentSMSVerificationErrorType,
  SegmentService
} from '@suzy/shared/data-access/tracking';
import { MissionService } from '@suzy/shared/tools/mission';
import { CountUpdateService } from '../../../../core/services/count-update.service';
import { filter, finalize, find, takeUntil } from 'rxjs/operators';
import { GoalService } from 'apps/crowdtap/src/app/core/services/goal.service';
import {
  SuzySdkService,
  RewardStatus,
  ChallengeType,
  TutorialStatus
} from '@suzy/shared/data-access/suzy-sdk';
import { GeneralRewardsComponent } from '../set-goal-components/rewards/rewards.component';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { trigger, transition, style, animate } from '@angular/animations';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import {
  AvailableChallenge,
  ChallengeInfo,
  ChallengeService,
  ChallengeStage
} from 'apps/crowdtap/src/app/core/services/challenge.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalQueueService } from 'apps/crowdtap/src/app/core/services/modal-queue-service';
import { PointsService } from '@suzy/crowdtap/tools/points';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppInputValidators } from 'apps/crowdtap/src/app/shared/app-input-validators';
import { SmsPromptService } from 'apps/crowdtap/src/app/shared/services/sms-prompt.service';

@Component({
  selector: 'app-general-header',
  templateUrl: './general-header.component.html',
  styleUrls: ['./general-header.component.scss'],
  animations: [
    trigger('ease', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1s 1s ease', style({ opacity: '1' }))
      ])
    ]),
    trigger('outIn', [
      transition(':enter', [
        style({ transform: 'translateY(50%)', opacity: '0' }),
        animate(
          '0.5s 1.5s ease',
          style({ opacity: '1', transform: 'translateY(0%)' })
        )
      ])
    ]),
    trigger('easeInOut', [
      transition(':enter', [
        style({ transform: 'scale(1.32)' }),
        animate('0.5s 0.5s ease', style({ transform: 'scale(1)' }))
      ])
    ])
  ]
})
export class GeneralHeaderComponent implements OnInit {
  options: AnimationOptions = {
    path: '../../../../../assets/CT_reward-goal_Confetti_lottie.json'
  };

  @ViewChild('navbar', { static: true }) navbar: ElementRef;
  @ViewChild('giftCardClaim') giftCardClaim: ElementRef<HTMLInputElement>;
  @ViewChild('reachedGoal') reachedGoal: ElementRef<HTMLInputElement>;
  @ViewChild('giftCardClaimFailed')
  giftCardClaimFailed: ElementRef<HTMLInputElement>;
  @ViewChild('giftCardClaimSuccess')
  giftCardClaimSuccess: ElementRef<HTMLInputElement>;
  @ViewChild('giftCardClaimVerifyPhone')
  giftCardClaimVerifyPhone: ElementRef<HTMLInputElement>;
  @ViewChild('challengeStart') challengeStart: ElementRef<HTMLInputElement>;

  @Output() openSignupModal = new EventEmitter();
  @ViewChild('toggleButton', { static: false }) toggleButton;
  @ViewChild('toggleButtonChallenge', { static: false }) toggleButtonChallenge;
  @ViewChild('menu', { static: false }) menu;
  @Input() headerPosition: string;
  @Input() viewKind: string;
  @Input() isMobile: boolean;
  userData: any;
  prevScrollpos = window.pageYOffset;
  el_navbar: HTMLElement;
  isAuth = false;
  confetti = false;
  isShow = true;
  isAnonymousView: boolean;
  isVerifiedView: boolean;
  streak: number;
  total_points = -1;
  enableStreak = false;
  isModal = false;
  dollerValue = 0;
  progressBarValue = 1;
  progressBarChallengeValue = 1;
  isCompleted = false;
  activeColor = '';
  isToolTip = false;
  isPageChange = false;
  isChange = true;
  isChallengeChange = false;
  isStreakChange = false;
  last_reward_utc = '';
  activeStep: number;
  stepColor = ['0', '1', '2', '3', '4'];
  unsubscribe$: Subject<boolean> = new Subject();
  isModalDisabled = false;
  isGoalModal = false;
  claimModalRequest: any;
  modalRequest: NgbModalRef;
  rewardModal: any;
  checkedLegal: boolean;
  showLegalError: boolean;
  isLoading = true;
  isClaimLoading = false;
  goalInfo: any;
  isGoalSet = false;
  goalProgressBarValue = 0;
  showRewardGoal: any;
  pillClicked = false;
  isEdit = false;
  showMilestoneTooltip: any;
  isResentLoading: boolean;
  isSent: boolean;
  isResent: boolean;
  isVerification: boolean;
  verificationCode: string = '';
  userTrackRewardId: string = '';
  mobileNumber: number;
  errormessage: string;
  displayTimer: any;
  lastOtpUtc: any;
  lastRewardUtc: any;
  millis: any;
  isTimer: boolean;
  errorState: any;
  public timerInterval: any;
  challengeInfo: ChallengeInfo[] = [];
  availableChallengeInfo: ChallengeInfo[] = [];
  failedChallengeInfo: ChallengeInfo[] = [];
  showChallenge = false;
  isModalChallenge = false;
  activeColorChallenge = '';
  CS = ChallengeStage;
  rewardStatus: number = 10;
  challengeType = ChallengeType;
  isSmsFirstFlowSignup = false;
  isVerifyEmailFlow = false;
  sendPhoneForm: UntypedFormGroup;
  phoneVerifyModal: any;
  phoneNumberSubmitInProgress?: boolean;
  phoneNumberVerification?: boolean;

  constructor(
    private profileMenuService: ProfileMenuService,
    private auth: AuthenticationService,
    public settingsService: SettingsService,
    private router: Router,
    private user: UserProfileService,
    private segmentService: SegmentService,
    private countUpdateService: CountUpdateService,
    private missionService: MissionService,
    private renderer: Renderer2,
    private sdk: SuzySdkService,
    private goalService: GoalService,
    private modalService: NgbModal,
    private launchDarklyService: LaunchDarklyService,
    private challengeService: ChallengeService,
    private translate: TranslateService,
    private modalQueueService: ModalQueueService,
    private pointsService: PointsService,
    private fb: FormBuilder,
    private smsPromptService: SmsPromptService
  ) {
    this.isSmsFirstFlowSignup =
      this.launchDarklyService.getCWDT2328MoveSMSQuestionFlag();
    this.isVerifyEmailFlow =
      this.launchDarklyService.getRW74And76AbilityToSignUpWithAMagicLink();
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        this.isModal &&
        e.target !== this.toggleButton.nativeElement &&
        e.target !== this.menu.nativeElement
      ) {
        this.isModal = false;
      } else if (
        this.isModalChallenge &&
        e.target !== this.toggleButtonChallenge.nativeElement &&
        e.target !== this.menu.nativeElement
      ) {
        this.isModalChallenge = false;
      }
    });
    const lists = [
      'link-expired',
      'terms-of-service',
      'privacy-policy',
      'cookie-policy',
      'notice-of-financial-incentive',
      'accessibility-statement',
      'biometric-data-policy'
    ];
    router.events.subscribe(val => {
      this.isModal = false;
      this.isModalChallenge = false;
      if (lists.includes(router.url.split('/')[1])) {
        this.isShow = false;
      }

      if (router.url.split('/')[1].includes('verify')) {
        this.isShow = false;
      }

      if (router.url.indexOf('/auth') > -1) {
        this.isAnonymousView = true;
      }

      const isSmsFirstFlowVerifiedView =
        this.isSmsFirstFlowSignup &&
        ((!this.isAnonymousView &&
          router.url.indexOf('/account-create-email') === -1) ||
          router.url.indexOf('/get-to-know-you') > -1 ||
          router.url.indexOf('/about-yourself') > -1 ||
          router.url.indexOf('/almost-there') > -1);

      const isVerifyEmailFlowVerifiedView =
        this.isVerifyEmailFlow &&
        ((!this.isAnonymousView &&
          router.url.indexOf('/account-create-email') === -1) ||
          router.url.indexOf('/email-sent') > -1);

      if (
        router.url.indexOf('/get-verified') > -1 ||
        router.url.indexOf('/set-reward-goal') > -1 ||
        isSmsFirstFlowVerifiedView ||
        isVerifyEmailFlowVerifiedView
      ) {
        this.isVerifiedView = true;
      }
    });
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    const keyPressed = event.keyCode;
    if (keyPressed === 27) {
      this.missionService.setMissionOpen(false);
    }
  }

  ngOnInit(): void {
    this.showMilestoneTooltip = this.launchDarklyService.getCWDT1448Flag();
    this.showRewardGoal =
      this.launchDarklyService.getCWDT742SM1562Flag() &&
      this.launchDarklyService.getCWDT742Flag();
    this.missionService
      .getMissionOpen()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(modalStatus => {
        this.isModalDisabled = modalStatus;
        if (modalStatus) {
          this.isModal = false;
          this.isModalChallenge = false;
        }
      });

    this.isAuth = this.auth.isAuthenticated();
    this.auth.ensureUser(true).subscribe(arg => {
      this.userData = arg;
      this.getCountValueUpdate();
    });

    this.el_navbar = this.navbar.nativeElement as HTMLElement;
    if (this.headerPosition && this.headerPosition === 'relative') {
      this.el_navbar.classList.add('relative-header');
    }

    if (this.viewKind !== 'legal') {
      this.goalService.claimModal$.subscribe((data: string) => {
        if (data == 'claimDialogue') {
          this.openClaimModal();
        } else if (data == 'rewardDialogue') {
          this.openRewardModal();
        } else if (data == 'taskboard-reached-goal') {
          this.openReachGoalModal();
        } else {
          this.rewardModal?.close();
        }
      });

      this.goalService.goalInfo$.subscribe((data: any) => {
        this.isEdit = data.isSetGoal;
        if (data.item && data.meta) {
          this.goalInfo = { ...data.item, ...data.meta };
          this.isGoalSet =
            data.item.user_reward_goal_status != 2 ? true : false;
          this.getProgressValue();

          if (this.pillClicked) {
            // so we don't track when something besides a pill click calls this
            this.pillClicked = false;
            this.segmentService.trackPointsPillTapped({
              reward_goal_points_cost: data.meta.points_required,
              reward_name: data.meta.reward_name,
              reward_photo: data.meta.photo.thumbs?.member?.url,
              reward_value: data.meta.reward_value,
              total_points: this.total_points,
              track_reward_id: data.meta.track_reward_id,
              user_track_reward_goal_id: data.item.user_track_reward_goal_id
            });
          }
        } else {
          this.goalInfo = undefined;
          this.isGoalSet = false;
          if (this.pillClicked) {
            this.pillClicked = false;
            this.segmentService.trackPointsPillTapped({
              total_points: this.total_points
            });
          }
        }
        this.isGoalModal =
          this.isGoalSet || this.goalService.getGoalInfo.isSetGoal;
        this.isLoading = true;
      });

      this.challengeService.availableChallenge$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((challenges: AvailableChallenge[]) => {
          if (challenges) {
            this.availableChallengeInfo = challenges.map(challenge => {
              return {
                points: challenge?.award_points,
                required:
                  challenge.challenge_type === ChallengeType.mission
                    ? challenge?.track_challenge_config?.mission
                        ?.required_mission_count
                    : challenge?.challenge_duration,
                progress: 0,
                end_utc: challenge ? new Date(challenge.end_utc) : undefined,
                updated_utc: undefined,
                challengeStage: ChallengeStage.available,
                track_challenge_id: challenge.track_challenge_id,
                challenge_period: challenge.challenge_period,
                challenge_type: challenge.challenge_type,
                special_tile:
                  challenge?.track_challenge_config?.mission
                    ?.special_tile_challenge ?? false
              };
            });

            challenges.forEach(challenge => {
              this.segmentService.challengeIntroModalViewed({
                track_challenge_id: challenge.track_challenge_id,
                track_id: challenge.track_id,
                challenge_name: challenge.challenge_name,
                challenge_description: challenge.description,
                challenge_period: challenge.challenge_period,
                challenge_points: challenge.award_points,
                challenge_type: challenge.challenge_type,
                start_utc: challenge.start_utc,
                expires_utc: challenge.end_utc
              });
            });

            this.openChallengeStartModal();
          } else {
            this.availableChallengeInfo = [];
          }
        });

      this.challengeService.inProgressChallenge$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((challenges: ChallengeInfo[]) => {
          if (challenges) {
            this.challengeInfo = challenges.filter(
              challenge => challenge.challengeStage !== ChallengeStage.failed
            );
            this.failedChallengeInfo = challenges.filter(
              challenge => challenge.challengeStage === ChallengeStage.failed
            );
          } else {
            this.challengeInfo = [];
          }
          this.showChallenge = this.challengeInfo.length > 0;

          if (this.challengeInfo.length > 0) {
            if (
              this.challengeInfo.find(
                challenge =>
                  challenge.challengeStage === ChallengeStage.inProgress
              )
            ) {
              this.challengeInfo = this.challengeInfo.filter(
                challenge =>
                  challenge.challengeStage !== ChallengeStage.awarded &&
                  challenge.challengeStage !== ChallengeStage.completed
              );
            } else {
              const firstCompleted = this.challengeInfo.find(
                challenge =>
                  challenge.challengeStage === ChallengeStage.awarded ||
                  challenge.challengeStage !== ChallengeStage.completed
              );
              this.challengeInfo = this.challengeInfo.filter(
                challenge =>
                  challenge.challengeStage !== ChallengeStage.awarded &&
                  challenge.challengeStage !== ChallengeStage.completed
              );
              if (firstCompleted) {
                this.challengeInfo.push(firstCompleted);
              }
            }
          }
        });

      this.modalQueueService.currentModal$
        .pipe(
          takeUntil(this.unsubscribe$),
          filter(
            nextModal =>
              nextModal && nextModal.startsWith('challenge-milestone-modal')
          )
        )
        .subscribe(() => {
          setTimeout(() => {
            this.activeColorChallenge =
              this.progressBarChallengeValue < 100
                ? 'progress-1'
                : 'progress-challenge-complete';
            this.getProgress(
              this.progressBarChallengeValue,
              'challenge-milestone'
            );
          }, 10);
        });

      this.pointsService.refresh$.subscribe((points: number) => {
        if (this.total_points !== points) {
          this.isChange = true;
          setTimeout(() => {
            this.total_points = points;
          }, 600);
          setTimeout(() => {
            this.isChange = false;
          }, 700);
        }
      });

      this.challengeService.streak$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(streak => {
          if (
            this.enableStreak &&
            this.streak !== streak &&
            streak !== undefined
          ) {
            this.isStreakChange = true;
            setTimeout(() => {
              this.streak = streak > 0 ? streak : undefined;
            }, 600);
            setTimeout(() => {
              this.isStreakChange = false;
            }, 700);
          } else {
            this.enableStreak = streak !== undefined;
            this.streak = streak > 0 ? streak : undefined;
          }
        });

      this.challengeService.milestoneModal$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(milestone => {
          if (
            milestone.find(
              m =>
                m.challengeStage === ChallengeStage.completed ||
                m.challengeStage === ChallengeStage.awarded
            )
          ) {
            setTimeout(() => {
              this.pointsService.refreshPoints();
            }, 1000);
          }
        });
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.loop = false;
  }

  getCountValueUpdate(): void {
    this.countUpdateService.getCountSource().subscribe((currentUser: any) => {
      if (
        !currentUser.usier_id &&
        this.userData?.tutorial_status === TutorialStatus.in_progress
      ) {
        this.userData.total_points = this.userData.tutorial_points ?? 0;
      }
      if (currentUser['old_value'] != -1) {
        this.isChange = true;
      }
      if (currentUser.old_value != undefined) {
        this.userData['old_value'] = -1;
      } else {
        (this.userData['old_value'] = this.userData['total_points']),
          (this.userData['total_points'] = currentUser.total_points);
        this.userData['last_reward_utc'] = currentUser.last_reward_utc;
      }
      this.goalService.getGoalData(
        currentUser.total_points
          ? currentUser.total_points
          : this.userData['old_value']
      );
      setTimeout(() => {
        this.getCalulate();
      }, 600);
      setTimeout(() => {
        this.isChange = false;
      }, 700);
    });
  }

  toggle(): void {
    this.pillClicked = true;
    this.isModal = !this.isModalDisabled && !this.isModal;
    this.isModalChallenge = false;
    this.profileMenuService.display.next(false);
    if (this.isModal && !this.goalService.isCache) {
      this.isLoading = false;
      this.goalService.getGoalData(this.userData['total_points']);
    } else {
      this.getProgressValue();
    }
  }

  toggleChallenge(e: Event): void {
    !this.isModalDisabled && e.stopPropagation();
    this.isModalChallenge =
      !this.isModalDisabled &&
      this.challengeInfo.length > 0 &&
      !this.isModalChallenge;
    this.isModal = false;
    this.profileMenuService.display.next(false);
    if (this.isModalChallenge) {
      this.challengeInfo.forEach(challenge => {
        this.segmentService.challengePillTapped({
          track_challenge_id: challenge.track_challenge_id,
          user_track_challenge_id: challenge.user_track_challenge_id,
          challenge_period: challenge.challenge_period,
          challengeStage: this.toSegmentStage(challenge.challengeStage),
          points: challenge.points,
          challenge_type: challenge.challenge_type,
          progress: challenge.progress,
          required: challenge.required
        });
      });
    }
  }

  private toSegmentStage(stage: ChallengeStage): SegmentChallengeStage {
    switch (stage) {
      case ChallengeStage.available:
        return SegmentChallengeStage.available;
      case ChallengeStage.inProgress:
        return SegmentChallengeStage.started;
      case ChallengeStage.awarded:
        return SegmentChallengeStage.awarded;
      case ChallengeStage.completed:
        return SegmentChallengeStage.completed;
      default:
        return undefined;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  getCalulate(): void {
    this.userData['old_value'] =
      typeof this.userData['old_value'] !== 'number'
        ? 0
        : this.userData['old_value'];
    this.userData['total_points'] =
      typeof this.userData['total_points'] !== 'number'
        ? this.userData['old_value']
        : this.userData['total_points'];

    const previousTrunk = Math.trunc(this.userData['old_value'] / 1000);
    const currentTrunk = Math.trunc(this.userData['total_points'] / 1000);
    this.total_points = this.userData['total_points'];
    if (this.userData['old_value'] > 0 && currentTrunk > previousTrunk) {
      this.showMessage();
    }
    this.activeStep = currentTrunk * 1000 + 1000;
    this.dollerValue = (currentTrunk + 1) * 5;
    const checkStep = Math.floor(this.total_points / 1000) % 5;
    this.activeColor = 'progress-' + (checkStep + 1);
    const value = (this.userData['total_points'] - currentTrunk * 1000) / 10;
    this.progressBarValue = value < 5 ? 5 : value;
    if (this.isGoalSet && this.showRewardGoal) {
      this.setGoalProgressBar();
    } else {
      setTimeout(() => {
        this.getProgress(this.progressBarValue, 'goal');
      }, 10);
    }
  }

  showMessage(): void {
    this.isCompleted = true;
    this.isToolTip = true;
    setTimeout(() => {
      this.isToolTip = false;
      this.isCompleted = false;
      this.countUpdateService.updateCount({ old_value: -1 });
    }, 5000);
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const currentScrollPos = window.pageYOffset;
    if (this.headerPosition !== 'relative') {
      if (this.prevScrollpos > currentScrollPos) {
        this.el_navbar.classList.remove('hide');
      } else {
        this.el_navbar.classList.add('hide');
      }
      this.prevScrollpos = currentScrollPos;
    }
  }

  onOpenSignupModal(displayText: string): void {
    this.openSignupModal.emit(displayText);
  }

  showProfileMenu(event: MouseEvent | KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.profileMenuService.toggleDisplay(true);
    this.isModal = false;
    this.isModalChallenge = false;
  }

  logoutHandler(): void {
    this.auth.logout(false, false, '/');
  }

  isDarkModeSelected$(): Observable<boolean> {
    return this.settingsService.isDarkModeSelected$;
  }

  getProgress(point: number, type: string): void {
    if (document.querySelector('.data-container-' + type)) {
      const dataContainer = document.querySelector('.data-container-' + type);
      dataContainer.setAttribute('data-value', point.toString());
      const meters = dataContainer.querySelectorAll('.meter');

      meters.forEach((path: any) => {
        // Get the length of the path
        const length = path.getTotalLength();
        // Get the value of the meter
        const value = parseInt(path.parentNode.getAttribute('data-value'));
        // Calculate the percentage of the total length
        const to = length * ((100 - value) / 100);
        path.getBoundingClientRect();
        // Set the Offset
        path.style.strokeDashoffset = Math.max(0, to);
      });
    }
  }

  segmentSetGoal(): void {
    this.segmentService.trackSetRewardGoalTapped({
      total_points: this.total_points,
      reward_goal_location: SegmentRewardGoalLocation.points_pill
    });
  }

  segmentUpdateGoal(): void {
    this.segmentService.trackRewardGoalUpdateTapped({
      reward_category: undefined,
      reward_goal_location: SegmentRewardGoalLocation.points_pill,
      reward_goal_points_cost: this.goalInfo.points_required,
      reward_name: this.goalInfo.reward_name,
      reward_photo: this.goalInfo.photo.thumbs?.member?.url,
      reward_value: this.goalInfo.reward_value,
      total_points: this.total_points,
      track_reward_id: this.goalInfo.track_reward_id,
      user_track_reward_goal_id: this.goalInfo.user_track_reward_goal_id
    });
  }

  segmentEditGoal(): void {
    this.segmentService.trackRewardGoalEditTapped({
      reward_goal_location: SegmentRewardGoalLocation.points_pill,
      reward_goal_points_cost: this.goalInfo.points_required,
      reward_name: this.goalInfo.reward_name,
      reward_photo: this.goalInfo.photo.thumbs?.member?.url,
      reward_value: this.goalInfo.reward_value,
      total_points: this.total_points,
      track_reward_id: this.goalInfo.track_reward_id,
      user_track_reward_goal_id: this.goalInfo.user_track_reward_goal_id
    });
  }

  openRewardModal() {
    this.isModal = false;
    this.rewardModal = this.modalService.open(GeneralRewardsComponent, {
      centered: true,
      windowClass: 'general-modal',
      modalDialogClass: 'set-goal-rewards-modal'
    });
  }

  getProgressValue() {
    const point = this.goalInfo?.reward_value > 5 ? 93 : 98;
    const percentage =
      (this.total_points / this.goalInfo?.points_required) * point;
    this.goalProgressBarValue = percentage >= point ? point : percentage;
    if (this.isGoalSet && this.showRewardGoal) {
      this.setGoalProgressBar();
    }
  }

  setGoalProgressBar() {
    this.progressBarValue =
      (this.total_points / this.goalInfo?.points_required) * 100;
    setTimeout(() => {
      this.activeColor = 'progress-1';
      this.getProgress(this.progressBarValue, 'goal');
    }, 10);
  }

  claimGiftCard(type?: string): void {
    if (!this.checkedLegal && type != 'resent') {
      this.showLegalError = true;
      return;
    }

    if (this.checkedLegal || type === 'resent') {
      if (type === 'resent') {
        this.isResentLoading = true;
      } else {
        this.isClaimLoading = true;
      }
      this.isClaimLoading = true;
      this.sdk.ProtocolRedeem.requestReward({
        track_reward_id: this.goalInfo?.track_reward_id
      }).subscribe(
        response => {
          this.isClaimLoading = false;
          this.isResentLoading = false;
          if (response.success) {
            if (
              response.detail.user_track_reward.reward_status ===
              RewardStatus.verification_required
            ) {
              this.errormessage = '';
              this.lastOtpUtc =
                response.detail.user_track_reward.verification_sent_utc;
              if (type === 'resent') {
                this.isSent = true;
                this.isResent = false;
              }
              this.isVerification = true;
              this.mobileNumber = response.item.mobile_phone;
              this.userTrackRewardId =
                response.detail.user_track_reward.user_track_reward_id;
              this.getRemainingTime(300000, this.lastOtpUtc);
              const verificationData = {
                mobileNumber:
                  '(xxx)-xxx-' + this.mobileNumber.toString().slice(-4),
                lastOtpUtc: this.lastOtpUtc,
                userTrackRewardId:
                  response.detail.user_track_reward.user_track_reward_id,
                reward_group_id: this.goalInfo?.reward_group_id
              };
              const checkSessionVerification = JSON.parse(
                sessionStorage.getItem('verificationFlow')
              );
              if (checkSessionVerification) {
                sessionStorage.removeItem('verificationFlow');
              }
              sessionStorage.setItem(
                'verificationFlow',
                JSON.stringify(verificationData)
              );
              this.segmentService.smsVerificationUponRedemptionViewed({
                points_cost: this.goalInfo.points_required,
                reward_category: this.goalInfo.reward_category,
                reward_name: this.goalInfo.reward_name,
                reward_photo: this.goalInfo.photo.thumbs?.member?.url,
                reward_value: this.goalInfo.reward_value,
                track_reward_id: this.goalInfo.track_reward_id,
                user_track_reward_id: this.userTrackRewardId,
                track_phone_number: this.mobileNumber
              });
            } else {
              this.userData = { ...response.item };
              this.countUpdateService.updateCount({
                last_reward_utc: this.userData.last_reward_utc,
                total_points: this.userData['total_points']
              });
              this.openSuccessModal();

              this.user.setCurrentUser(this.userData);
              const u: any = this.user.getCurrentUser();
              this.segmentService.identify(u.user_id, {
                total_points: u.total_points
              });
            }
          } else {
            this.claimModalRequest.close();

            if (
              response.message_token ===
              'award.Thisaccount.VerifiedPhoneNumberNotAvailable'
            ) {
              this.sendPhoneForm = this.fb.group({
                phone: ['', [Validators.required, AppInputValidators.phoneUS]]
              });
              this.phoneVerifyModal = this.modalService.open(
                this.giftCardClaimVerifyPhone,
                {
                  centered: true,
                  windowClass: 'general-modal',
                  modalDialogClass: 'rewards-modal'
                }
              );
              return;
            }

            this.modalService.open(this.giftCardClaimFailed, {
              centered: true,
              windowClass: 'general-modal',
              modalDialogClass: 'rewards-modal'
            });
          }
        },
        error => {
          this.isClaimLoading = false;
          this.isResentLoading = false;
          this.claimModalRequest.close();
          this.modalService.open(this.giftCardClaimFailed, {
            centered: true,
            windowClass: 'general-modal',
            modalDialogClass: 'rewards-modal'
          });
        }
      );
    }
  }

  openClaimModal(forceVerification?: boolean): void {
    this.checkedLegal = false;
    this.showLegalError = false;
    const checkSessionVerification = JSON.parse(
      sessionStorage.getItem('verificationFlow')
    );
    if (
      checkSessionVerification &&
      checkSessionVerification.reward_group_id === this.goalInfo.reward_group_id
    ) {
      this.isVerification = true;
      this.errormessage = '';
      this.verificationCode = '';
      this.lastOtpUtc = checkSessionVerification.lastOtpUtc;
      this.mobileNumber = checkSessionVerification.mobileNumber;
      this.userTrackRewardId = checkSessionVerification.userTrackRewardId;
      this.getRemainingTime(300000, this.lastOtpUtc);
      this.segmentService.smsVerificationUponRedemptionViewed({
        points_cost: this.goalInfo.points_required,
        reward_category: this.goalInfo.reward_category,
        reward_name: this.goalInfo.reward_name,
        reward_photo: this.goalInfo.photo.thumbs?.member?.url,
        reward_value: this.goalInfo.reward_value,
        track_reward_id: this.goalInfo.track_reward_id,
        user_track_reward_id: this.userTrackRewardId,
        track_phone_number: this.mobileNumber
      });
    } else if (forceVerification) {
      this.isVerification = true;
      this.phoneNumberVerification = true;
      this.errormessage = '';
      this.verificationCode = '';
    } else {
      this.isVerification = false;
      this.phoneNumberVerification = false;
    }
    if (this.modalRequest) {
      this.modalRequest.close();
    }
    this.claimModalRequest = this.modalService.open(this.giftCardClaim, {
      centered: true,
      windowClass: 'general-modal',
      modalDialogClass: 'rewards-modal'
    });

    this.claimModalRequest.result.catch(() => {
      this.modalQueueService.modalClosed('goal-reached-claim');
    });
  }

  openSuccessModal(): void {
    if (this.claimModalRequest) {
      this.claimModalRequest.close();
    }
    this.modalRequest = this.modalService.open(this.giftCardClaimSuccess, {
      centered: true,
      windowClass: 'general-modal',
      modalDialogClass: 'rewards-modal'
    });

    this.modalRequest.result.finally(() => {
      this.modalQueueService.modalClosed('goal-reached-claim');
    });
  }

  openReachGoalModal(): void {
    const modalId = 'goal-reached-claim';
    this.modalQueueService.currentModal$
      .pipe(find(nextModal => nextModal === modalId))
      .subscribe(() => {
        this.segmentService.trackRewardGoalReachedModalViewed({
          points_cost: this.goalInfo.points_required,
          reward_goal_points_cost: this.goalInfo.points_required,
          reward_id: this.goalInfo.track_reward_id,
          reward_name: this.goalInfo.reward_name,
          reward_photo: this.goalInfo.photo.thumbs?.member?.url,
          reward_value: this.goalInfo.reward_value,
          total_points: this.total_points,
          user_track_reward_goal_id: this.goalInfo.user_track_reward_goal_id
        });
        this.confetti = false;
        this.modalRequest = this.modalService.open(this.reachedGoal, {
          centered: true,
          windowClass: 'general-modal reachedGoal',
          modalDialogClass: 'rewards-modal reachGoal-modal'
        });
        setTimeout(() => {
          this.confetti = true;
        }, 600);

        this.modalRequest.result.catch(() => {
          this.modalQueueService.modalClosed(modalId);
        });
      });
    this.modalQueueService.queueModal(modalId);
  }

  openChallengeStartModal(): void {
    const modalId = 'challenge-start-modal';
    this.modalQueueService.currentModal$
      .pipe(find(nextModal => nextModal === modalId))
      .subscribe(() => {
        this.isModalChallenge = false;
        this.modalRequest = this.modalService.open(this.challengeStart, {
          centered: true,
          windowClass: 'general-modal startChallenge',
          modalDialogClass:
            'challenge-modal startChallenge-modal' +
            (this.availableChallengeInfo.length > 1 ? ' multiple' : '')
        });

        this.modalRequest.result
          .catch(() => {
            this.startChallenge(false);
          })
          .finally(() => {
            this.modalQueueService.modalClosed(modalId);
          });
      });
    this.modalQueueService.queueModal(modalId);
  }

  startChallenge(closeModal: boolean): void {
    this.availableChallengeInfo.forEach(challenge => {
      this.segmentService.challengeButtonTapped({
        track_challenge_id: challenge.track_challenge_id,
        challenge_period: challenge.challenge_period,
        points: challenge.points,
        challenge_type: challenge.challenge_type
      });
    });

    this.challengeService.startChallenge(
      this.availableChallengeInfo
        //.filter(challenge => challenge.challenge_type !== ChallengeType.streak) // streak challenges auto join on BE
        .map(c => c.track_challenge_id)
    );
    if (closeModal) {
      this.modalRequest.close();
    }
  }

  calcTimeRemaining(challenge: ChallengeInfo): string {
    return this.challengeService.getTimeRemaining(challenge);
  }

  onCloseSuccessModal(): void {
    this.modalRequest.close();
  }

  redirectToStatus(): void {
    this.router.navigate(['/rewards/status']);
    this.onCloseSuccessModal();
  }

  submitOtp(): void {
    this.segmentService.smsVerificationUponRedemptionCodeSubmissionTapped({
      points_cost: this.goalInfo.points_required,
      reward_category: this.goalInfo.reward_category,
      reward_name: this.goalInfo.reward_name,
      reward_photo: this.goalInfo.photo.thumbs?.member?.url,
      reward_value: this.goalInfo.reward_value,
      track_reward_id: this.goalInfo.track_reward_id,
      user_track_reward_id: this.userTrackRewardId,
      track_phone_number: this.mobileNumber
    });
    if (this.verificationCode.trim().length === 0) {
      this.errormessage = 'userTrackReward.pleaseEnterValidCode';
      this.errorState = {
        sms_verification_error_type:
          SegmentSMSVerificationErrorType.no_code_provided,
        error_message: this.errormessage,
        error_ui_message: this.errormessage,
        error_source: SegmentErrorSource.frontend
      };
      this.segmentVerificationError();
      return;
    }
    this.isClaimLoading = true;
    this.sdk.ProtocolRedeem.verifyComplete(this.userTrackRewardId, {
      user_track_reward_id: this.userTrackRewardId,
      verification_confirm_token: this.verificationCode
    }).subscribe(
      response => {
        this.isClaimLoading = false;
        if (response.success) {
          this.verificationCode = '';
          clearInterval(this.timerInterval);
          this.resetVerificationFlow();
          this.lastRewardUtc =
            response.detail.user_track_reward.verification_completed_utc;
          this.getRemainingTime(120000, this.lastRewardUtc);
          this.userData.last_reward_utc = this.lastRewardUtc;
          this.userData.total_points = response.item.total_points;
          this.countUpdateService.updateCount({
            last_reward_utc: this.userData.last_reward_utc,
            total_points: this.userData['total_points']
          });
          if (!this.smsPromptService.isGetNotifiedModalSeen) {
            this.smsPromptService.openModal().result.then(() => {
              this.openSuccessModal();
            });
          } else {
            this.openSuccessModal();
          }
          this.user.setCurrentUser(this.userData);
        } else {
          this.errormessage = response.message_token;
          this.errorState = {
            sms_verification_error_type:
              response.message_token === 'userTrackReward.TokenMismatch'
                ? SegmentSMSVerificationErrorType.invalid_code
                : SegmentSMSVerificationErrorType.expired_code,
            error_message: response.message,
            error_ui_message: this.translate.instant(this.errormessage),
            error_source: SegmentErrorSource.backend
          };
        }
      },
      error => {
        this.isClaimLoading = false;
      }
    );
  }

  submitPhoneVerificationOtp(): void {
    if (this.verificationCode.trim().length === 0) {
      this.errormessage = 'userTrackReward.pleaseEnterValidCode';
      this.errorState = {
        sms_verification_error_type:
          SegmentSMSVerificationErrorType.no_code_provided,
        error_message: this.errormessage,
        error_ui_message: this.errormessage,
        error_source: SegmentErrorSource.frontend
      };
      this.segmentVerificationError();
      return;
    }

    this.isClaimLoading = true;
    this.sdk.ProtocolRegister.verifyPhoneComplete({
      token: this.verificationCode
    }).subscribe(
      response => {
        if (response?.success) {
          if (!this.smsPromptService.isGetNotifiedModalSeen) {
            this.smsPromptService.openModal().result.then(() => {
              this.redeemRequestReward();
            });
          } else {
            this.redeemRequestReward();
          }
        } else {
          this.isClaimLoading = false;
          this.errormessage = response.message;
        }
      },
      error => {
        this.isClaimLoading = false;
        this.segmentVerificationError();
      }
    );
  }

  redeemRequestReward() {
    this.sdk.ProtocolRedeem.requestReward({
      track_reward_id: this.goalInfo?.track_reward_id
    }).subscribe(rewardResponse => {
      this.isClaimLoading = false;
      this.isResentLoading = false;
      if (rewardResponse.success) {
        this.verificationCode = '';
        clearInterval(this.timerInterval);
        this.resetVerificationFlow();
        this.lastOtpUtc = new Date();
        this.getRemainingTime(120000, this.lastOtpUtc);
        this.userData = { ...rewardResponse.item };
        this.countUpdateService.updateCount({
          last_reward_utc: this.userData.last_reward_utc,
          total_points: this.userData['total_points']
        });

        this.openSuccessModal();
        this.user.setCurrentUser(this.userData);
        const u: any = this.user.getCurrentUser();
        this.segmentService.identify(u.user_id, {
          total_points: u.total_points
        });
      } else {
        this.claimModalRequest.close();
        this.modalService.open(this.giftCardClaimFailed, {
          centered: true,
          windowClass: 'general-modal',
          modalDialogClass: 'rewards-modal'
        });
      }
    });
  }

  resentOtp(): void {
    this.claimGiftCard('resent');
    this.segmentService.smsVerificationUponRedemptionResendCodeTapped({
      points_cost: this.goalInfo.points_required,
      reward_category: this.goalInfo.reward_category,
      reward_name: this.goalInfo.reward_name,
      reward_photo: this.goalInfo.photo.thumbs?.member?.url,
      reward_value: this.goalInfo.reward_value,
      track_reward_id: this.goalInfo.track_reward_id,
      user_track_reward_id: this.userTrackRewardId,
      track_phone_number: this.mobileNumber
    });
  }

  allowOnlynumber(event): boolean {
    this.errormessage = '';
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getRemainingTime(millisecond: number, lastUtc: any): void {
    clearInterval(this.timerInterval);
    const currrentTime = new Date().getTime() - Date.parse(lastUtc);
    this.millis = millisecond - currrentTime;
    if (Math.sign(this.millis) != -1 && Math.sign(this.millis) != 0) {
      this.setDisplayTimer();
      this.isTimer = true;
      this.timerInterval = setInterval(() => {
        this.millis -= 1000;
        this.setDisplayTimer();
      }, 1000);
    } else {
      this.isTimer = false;
      this.resentEnableOtp();
      clearInterval(this.timerInterval);
    }
  }

  setDisplayTimer(): void {
    const mins = Math.floor((this.millis % (1000 * 60 * 60)) / (1000 * 60)),
      secs = Math.floor((this.millis % (1000 * 60)) / 1000);
    this.displayTimer = mins + 'm ' + secs + 's';
    if (
      (mins === 0 && secs === 0) ||
      Math.sign(mins) === -1 ||
      Math.sign(secs) === -1
    ) {
      this.isTimer = false;
      this.resentEnableOtp();
      clearInterval(this.timerInterval);
    }
  }

  resentEnableOtp() {
    const checkSessionVerification = JSON.parse(
      sessionStorage.getItem('verificationFlow')
    );
    if (checkSessionVerification || this.phoneNumberVerification) {
      this.isResent = true;
      this.isSent = false;
    }
  }

  resetVerificationFlow() {
    sessionStorage.removeItem('verificationFlow');
    this.isVerification = false;
    this.phoneNumberVerification = false;
    this.isSent = false;
    this.isResent = false;
  }

  segmentVerificationError() {
    this.segmentService.smsVerificationUponRedemptionCodeErrorViewed({
      sms_verification_error_type: this.errorState.sms_verification_error_type,
      error_message: this.errorState.error_message,
      error_ui_message: this.errorState.error_ui_message,
      error_source: this.errorState.error_source,
      points_cost: this.goalInfo.points_required,
      reward_category: this.goalInfo.reward_category,
      reward_name: this.goalInfo.reward_name,
      reward_photo: this.goalInfo.photo.thumbs?.member?.url,
      reward_value: this.goalInfo.reward_value,
      track_reward_id: this.goalInfo.track_reward_id,
      user_track_reward_id: this.userTrackRewardId,
      track_phone_number: this.mobileNumber
    });
  }

  submitPhoneNumber(): void {
    if (!this.sendPhoneForm.valid || this.phoneNumberSubmitInProgress) {
      return;
    }

    this.phoneNumberSubmitInProgress = true;
    const phoneNumber = this.sendPhoneForm.controls['phone'].value.replace(
      /\D/g,
      ''
    );

    this.segmentService.track('SMS Verification Submitted');
    this.sdk.ProtocolRegister.verifyPhoneStart({
      mobile_phone: `+1${phoneNumber}`
    })
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.phoneNumberSubmitInProgress = false;
          });
        })
      )
      .subscribe(data => {
        if (!data.success) {
          this.sendPhoneForm.get('phone').setErrors({ server: data.message });
          return;
        } else {
          clearInterval(this.timerInterval);
          this.lastOtpUtc = new Date();
          this.getRemainingTime(30000, this.lastOtpUtc);
          this.mobileNumber = phoneNumber;
          this.phoneVerifyModal.close();
          this.openClaimModal(true);
        }
      });
  }

  resentPhoneVerificationOtp(): void {
    if (this.phoneNumberSubmitInProgress || this.isResentLoading) {
      return;
    }
    this.isResentLoading = true;
    this.isClaimLoading = true;
    this.segmentService.track('SMS Verification Submitted');
    this.sdk.ProtocolRegister.verifyPhoneStart({
      mobile_phone: `+1${this.mobileNumber}`
    })
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.isResentLoading = false;
            this.isClaimLoading = false;
          });
        })
      )
      .subscribe(data => {
        if (!data.success) {
          this.errormessage = data.message;
          return;
        }

        this.errormessage = '';
        this.isSent = true;
        this.isResent = false;
        clearInterval(this.timerInterval);
        this.lastOtpUtc = new Date();
        this.getRemainingTime(30000, this.lastOtpUtc);
      });
  }
}
