import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Mission } from '../../../views/actions/mission.model';
import {
  ActionKind,
  MissionKind,
  SuzySdkService
} from '@suzy/shared/data-access/suzy-sdk';
import { ActionsResolverService } from '../../../views/actions/actions-resolver.service';
import { ActionComponent } from '../../../views/actions/shared/action-component';
import { ActionsService } from '../actions.service';
import { StoreService } from '../../../core/services/store.service';
import { Router } from '@angular/router';
import {
  SegmentService,
  segment_special_tile
} from '@suzy/shared/data-access/tracking';
import { CoverComponent } from '../cover/cover.component';
import { TranslateService } from '@ngx-translate/core';
import { PreviewService } from '../../preview/preview.service';
import { MissionService } from 'libs/shared/tools/mission/src/lib/mission.service';
import { SpecialMissionService } from '../../../core/services/special-mission.service';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { ModalQueueService } from '../../../core/services/modal-queue-service';
import { SettingsService } from '../../../core/services/settings.service';
import { ExternalLinkService } from '../../external-link/external-link.service';
import { SpecialTileService } from '@suzy/shared/special-tile';

@Component({
  selector: 'app-action-loader',
  templateUrl: './action-loader.component.html',
  styleUrls: ['./action-loader.component.scss']
})
export class ActionLoaderComponent implements OnInit, OnDestroy {
  @ViewChild('questionHost', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;
  mission: Mission;
  action: any;
  subject: Subject<any> = new Subject<any>();
  actionComponent: any;
  settings: any;
  messageList = [
    this.translate.instant('dashboard.opinionsMatter'),
    this.translate.instant('dashboard.onARoll'),
    this.translate.instant('dashboard.keepEarning'),
    this.translate.instant('dashboard.helpingChoices')
  ];
  message = '';
  completedMissionData: any;
  actionService: ActionsService;
  missionHasCover: boolean;
  removePointsAnimation: boolean;
  specialType: segment_special_tile;
  message2 = '';
  specialMissions: boolean;
  isPreview: boolean;
  isExternal: boolean;
  isGlobal: boolean;

  readonly specialTileType = segment_special_tile;
  specialTileName: string;

  constructor(
    private renderer: Renderer2,
    private actionResolver: ActionsResolverService,
    public modal: NgbActiveModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    private storeService: StoreService,
    private translate: TranslateService,
    private router: Router,
    private sdk: SuzySdkService,
    private segmentService: SegmentService,
    private hostElement: ElementRef,
    private previewService: PreviewService,
    private missionService: MissionService,
    private specialMissionService: SpecialMissionService,
    private specialMission: SpecialMissionService,
    private launchDarklyService: LaunchDarklyService,
    private modalQueueService: ModalQueueService,
    private settingsService: SettingsService,
    private externalLinkService: ExternalLinkService,
    private specialTileService: SpecialTileService
  ) {}

  initialize(
    mission: any,
    action: any,
    actionComponent: any,
    settings: any,
    actionService: ActionsService
  ): void {
    this.mission = mission;
    this.action = action;
    this.actionComponent = actionComponent;
    this.settings = settings;
    this.actionService = actionService;
    if (this.mission.color === undefined) {
      this.mission.color = 1;
    }
  }

  setMission(mission: any): void {
    if (
      this.specialMission.Properties.crowdtap_special_missions[0]
        ?.mission_id === mission.mission_id
    ) {
      $('#reverseAnimation').hide();
      if ($('.reward-earned-modal').length) {
        $('.d-block').addClass('reward-earned-modal specialMission');
      } else {
        $('.d-block').addClass('general-modal specialMission');
      }
    }
    this.mission = mission;
    this.action = mission.first_Action;
  }

  checkSpecialMission(mission_id: string): void {
    this.specialMissions =
      this.specialMissionService.Properties.crowdtap_special_missions.find(
        mission => mission.mission_id === mission_id
      );
  }

  ngOnInit(): void {
    this.checkSpecialMission(this.mission.mission_id);
    this.specialType = this.mission.specialType;
    this.setMessages();
    this.renderer.addClass(document.body, 'isActionModal');
    this.loadComponent(this.actionComponent, this.mission, this.action);
    this.isPreview = this.settingsService.isPreviewApp();
    this.isExternal = this.settingsService.isExternalApp();
    this.isGlobal = this.settingsService.isGlobalApp();

    this.specialTileName = this.specialTileService.getSpecialTileName(
      this.mission.external_json
    );
  }

  setMessages(): void {
    if (
      this.specialType === segment_special_tile.profile_tile &&
      this.launchDarklyService.getCWDT1308Flag()
    ) {
      this.message = this.launchDarklyService.getCWDT1308Flag()[0];
      this.message2 = this.launchDarklyService.getCWDT1308Flag()[1];
    } else if (this.specialType === segment_special_tile.double_points_tile) {
      this.message = this.translate.instant('specialTile.youveScored');
    } else {
      this.message =
        this.messageList[Math.floor(Math.random() * this.messageList.length)];
    }
  }

  @HostListener('paste', ['$event'])
  onPaste($event: Event): void {
    $event.preventDefault();
  }

  loadComponent(actionComponent, mission: Mission, action: any): void {
    this.previewService.currentAction$.next(action);
    const viewContainerRef = this.container;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(actionComponent);
    viewContainerRef.clear();

    if (componentFactory) {
      const componentRef = viewContainerRef.createComponent(componentFactory);
      const instance = componentRef.instance as ActionComponent;
      if (!mission.monadic_variant_key) {
        // if no monadic_variant_key is present
        this.missionService.chooseMonadicKey(mission);
      }
      instance.mission = mission;
      instance.action = action;
      instance['init'].apply(instance);
      instance.subject.subscribe(
        this.onCurrentInstanceSubjectChange.bind(this)
      );
    }
  }

  onCurrentInstanceSubjectChange(data): void {
    if (data && typeof data === 'object') {
      if (this.mission.mission_kind === MissionKind.standard || !data?.item) {
        // mission_kind seems to switch to standard when it's completed
        this.segmentService.trackMissionComplete({
          action_count: this.mission.action_count,
          brand_id: this.mission.brand_id,
          mission_id: this.mission.mission_id,
          points_earned:
            data?.detail?.mission_points ?? data?.detail?.points_awarded,
          mission_kind: this.mission.mission_kind,
          tag: this.specialTileName,
          weight: this.mission.weight
        });

        data['currentMissionIndex'] = this.storeService.currentMissionIndex;
        this.completedMissionData = data;
        // Snackbar cannot wait, so we're assuming queue is empty
        this.modalQueueService.queueModal('snackbar' + this.mission.mission_id);
        this.hostElement.nativeElement.classList.add('is-answering');
        this.subject.next(data);
        this.storeService.missionCompleted$.next({
          status: 'completed',
          completedMissionData: data,
          mission_id: this.mission.mission_id,
          specialType: this.mission.specialType
        });
        this.removePointsAnimation = false;
        if (!this.mission.first_time && this.router.url !== '/in-progress') {
          this.doNextMission(this.mission);
        } else {
          setTimeout(() => {
            this.modal.dismiss();
            if (
              data.detail &&
              data.detail.user_total_points &&
              data.detail.user_total_points % 500 !== 0
            ) {
              this.storeService.missionPointsEarned$.next({
                mission_id: this.mission.mission_id,
                earnedPoint: false
              });
            }
          }, 2000);
        }
        this.actionService.currentActionStepPriority$.next(-1);
      } else {
        this.actionService.currentActionStepPriority$.next(
          data.item.step_priority
        );
        const wrapper = this.hostElement.nativeElement.querySelector(
          '.action-modal-container'
        );
        wrapper.classList.add('is-hidden');

        setTimeout(() => {
          const nextAction = data.item;
          const actionComponent =
            this.actionResolver.resolveActionType(nextAction);
          if (
            this.isPreview &&
            nextAction.action_kind === ActionKind.auto_assign
          ) {
            nextAction.auto_assign.options.forEach(option => {
              if (
                data.detail.auto_assign_answers.action_setting_ids[0] ===
                option.answer_id
              ) {
                option.isSelected = true;
              }
            });
          }
          this.loadComponent(actionComponent, this.mission, nextAction);
          wrapper.classList.remove('is-hidden');
        }, 1000);
      }
      // Preview/External Ending Components
      if (this.isPreview || this.isExternal) {
        this.doEndingScreen(data);
      }
    } else {
      if (data === 'skip') {
        // not seeing skips land here, Segment tracking in Cover
        this.subject.next(data);
        this.mission['status'] = true;
        this.mission['answer_status'] = 'skipped';
        this.storeService.missionCompleted$.next({
          status: 'skipped',
          mission: this.mission
        });
      }
    }
  }

  doAction(nextMission): void {
    const actionComponent = this.actionResolver.resolveActionType(
      nextMission.first_action
    );

    const wrapper = this.hostElement.nativeElement.querySelector(
      '.action-modal-container'
    );
    $('.specialMission').removeClass('general-modal specialMission');
    setTimeout(() => {
      if (actionComponent) {
        this.loadComponent(
          actionComponent,
          nextMission,
          nextMission.first_action
        );

        this.setMission(nextMission);
      }
    }, 1000);
  }

  doMissionWithCover(mission: any): void {
    const wrapper = this.hostElement.nativeElement.querySelector(
      '.action-modal-container'
    );
    wrapper.classList.add('is-hidden');
    $('.specialMission').removeClass('general-modal specialMission');
    setTimeout(() => {
      this.missionHasCover = true;
      const coverComponent = CoverComponent as any;
      const viewContainerRef = this.container;
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(coverComponent);

      viewContainerRef.clear();

      if (componentFactory) {
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const instance = componentRef.instance as CoverComponent;
        instance.description = mission.mission_description;
        instance.title = mission.mission_caption;
        instance.kind = mission.mission_kind;
        if (!mission.monadic_variant_key) {
          // if no monadic_variant_key is present
          this.missionService.chooseMonadicKey(mission);
        }
        instance.mission = mission;
        instance.action = mission.first_action;
        instance.actionService = this.actionService;
      }

      this.setMission(mission);
      wrapper.classList.remove('is-hidden');
    }, 1000);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'isActionModal');
    this.subject.complete();
    this.subject = undefined;
  }

  doNextMission(currentMission: any): void {
    const nextMission = this.storeService.getNextMission(
      currentMission.mission_id
    );

    if (!nextMission) {
      if (this.isPreview || this.isExternal || this.isGlobal) {
        this.modal.dismiss();
        this.modalQueueService.modalClosed(
          'snackbar' + currentMission.mission_id
        );
      } else {
        setTimeout(() => {
          this.removePointsAnimation = true;
          if (!this.storeService.allActionsAnsweredPending) {
            this.storeService.missionCompleted$.next({ status: 'reset' });
          }
          setTimeout(() => {
            this.modalQueueService.modalClosed(
              'snackbar' + currentMission.mission_id
            );
          }, 500);
        }, 2000);
      }
      return;
    }

    this.missionHasCover = false;

    setTimeout(() => {
      this.removePointsAnimation = true;
      setTimeout(() => {
        this.completedMissionData = undefined;
        this.checkSpecialMission(nextMission.mission_id);
        this.specialType = nextMission.specialType;
        this.setMessages();
        this.modalQueueService.modalClosed(
          'snackbar' + currentMission.mission_id
        );
      }, 500);
    }, 2000);

    this.segmentService.trackMission('Mission Started', {
      action_count: nextMission.action_count,
      brand_id: nextMission.brand_id,
      mission_id: nextMission.mission_id,
      points_available: nextMission.points,
      mission_kind: nextMission.mission_kind,
      has_cover: false,
      monadic_variant_quantity: nextMission.monadic_variants
        ? nextMission.monadic_variants.length
        : undefined,
      tag: this.specialTileName,
      weight: nextMission.weight
    });

    this.storeService.currentMissionIndex =
      this.storeService.missions.findIndex(m => {
        return m.mission_id === nextMission.mission_id;
      });

    switch (nextMission.mission_kind) {
      case MissionKind.survey:
      case MissionKind.splittesting:
      case MissionKind.sharing:
      case MissionKind.screening:
      case MissionKind.maxdiff:
      case MissionKind.wave:
        this.doMissionWithCover(nextMission);
        break;
      default:
        this.doAction(nextMission);
    }
  }

  doEndingScreen(data: any): void {
    if (this.mission.mission_kind === MissionKind.external_link) {
      if (
        (data.currentMissionIndex === 0 && data.success === true) ||
        !data.success
      ) {
        this.externalLinkService
          .showEndingComponent(
            data,
            this.mission,
            this.isPreview,
            this.isExternal
          )
          .subscribe(response => {
            if (response === 'refresh' && this.isPreview) {
              this.previewService.restartMission$.next('restartMission');
            }
          });
      }
    } else {
      if (data.message_token === 'mission.ScreenedOut') {
        this.previewService.showNotEligibleComponent().subscribe(response => {
          if (response === 'refresh') {
            this.previewService.restartMission$.next('restartMission');
          }
        });
      } else if (data.currentMissionIndex === 0 && data.success === true) {
        this.previewService
          .showPreviewEndingComponent('Survey Complete')
          .subscribe(response => {
            if (response === 'refresh') {
              this.previewService.restartMission$.next('restartMission');
            }
          });
      }
    }
  }
}
