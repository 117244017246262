import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { Router } from '@angular/router';
import { GeneralRewardsComponent } from '../rewards/rewards.component';
import { GoalService } from 'apps/crowdtap/src/app/core/services/goal.service';
import { DeviceService } from '@suzy/shared/tools/device';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { SmsPromptService } from '../../../../../shared/services/sms-prompt.service';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
@Component({
  selector: 'app-rewards-list',
  templateUrl: './rewards-list.component.html',
  styleUrls: ['./rewards-list.component.scss']
})
export class GeneralRewardsListComponent implements OnInit {
  @ViewChild('rewardSuccess') rewardSuccess: ElementRef<HTMLInputElement>;
  @ViewChild('rewardFailed') rewardFailed: ElementRef<HTMLInputElement>;
  @Input() currentUser: any;
  @Input() actionClick: boolean;
  @Input() trackRewards: Array<any>;
  @Input() isSignup = false;
  @Output() selectCard = new EventEmitter();
  currentTrackReward: any;
  selectedRewardValue: any = {};
  modalRequest: any;
  modalSuccess: any;
  isLoading: boolean;
  rewardDialog: any;
  trackReward: any;
  index: any = -1;
  progressBarValue: any = 0;
  goalInfo: any;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private sdk: SuzySdkService,
    private router: Router,
    private goalService: GoalService,
    private deviceService: DeviceService,
    private segmentService: SegmentService,
    private smsPromptService: SmsPromptService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.index !== -1 && this.actionClick) {
      this.goalService.setClaimModal('');
      this.onOpenRewardModal(this.rewardDialog, this.trackReward, this.index); // the answer is here
    }
  }

  onOpenRewardModal(content: any, trackReward: any, index: number): void {
    this.currentTrackReward = trackReward;
    this.segmentService.trackRewardGoalAmountsViewed({
      reward_category:
        this.currentTrackReward?.track_rewards[0].reward_category,
      reward_id: this.currentTrackReward?.reward_group_id,
      reward_name: this.currentTrackReward?.reward_group_name,
      reward_photo:
        this.currentTrackReward?.track_rewards[0].photo.thumbs?.member?.url,
      total_points: this.currentUser?.total_points
    });
    this.selectedRewardValue = {};
    this.setDefaultRewardValue();
    this.modalRequest = this.modalService.open(content, {
      centered: true,
      windowClass: 'general-modal',
      modalDialogClass: this.deviceService.isMobile()
        ? 'set-goal-rewards'
        : 'set-goal-rewards-modal'
    });
  }

  selectValue(reward: any): void {
    this.selectedRewardValue = reward;
  }

  setDefaultRewardValue(): void {
    const filteredValues = this.currentTrackReward.track_rewards.filter(
      reward => reward.points_required <= this.currentUser.total_points
    );
    const sortingValues = filteredValues.sort((a, b) =>
      a.reward_value > b.reward_value ? 1 : -1
    );
    this.selectedRewardValue = sortingValues[sortingValues.length - 1];
    this.selectValue(this.currentTrackReward.track_rewards[0]);
  }

  editGoal(type: string) {
    type === 'success' ? this.modalSuccess.close() : this.modalRequest.close();
    setTimeout(() => {
      this.modalService.open(GeneralRewardsComponent, {
        centered: true,
        windowClass: 'general-modal',
        modalDialogClass: this.deviceService.isMobile()
          ? 'set-goal-rewards'
          : 'set-goal-rewards-modal'
      });
    }, 200);
  }

  saveGoal(): void {
    if (this.selectedRewardValue) {
      this.isLoading = true;
      this.sdk.ProtocolRedeem.createGoal({
        track_reward_id: this.selectedRewardValue.track_reward_id
      }).subscribe(
        response => {
          this.isLoading = false;
          if (response.success) {
            this.segmentService.trackRewardGoalConfirmationViewed({
              reward_category: this.selectedRewardValue.reward_category,
              reward_goal_points_cost: response.meta?.points_required,
              reward_id: response.meta?.track_reward_id,
              reward_name: response.meta?.reward_name,
              reward_photo: response.meta?.photo.thumbs?.member?.url,
              reward_value: response.meta?.reward_value,
              total_points: this.currentUser?.total_points,
              track_reward_id: response.meta?.track_reward_id,
              user_track_reward_goal_id:
                response.item?.user_track_reward_goal_id
            });
            response.isSetGoal = true;
            this.goalService.setGoalInfo(response);
            this.goalInfo = { ...response.meta, ...response.item };
            const point = this.goalInfo?.reward_value > 5 ? 93 : 98;
            const percentage =
              (this.currentUser?.total_points /
                response.meta?.points_required) *
              point;
            this.progressBarValue = percentage >= point ? point : percentage;
            if (!this.smsPromptService.isGetNotifiedModalSeen) {
              this.modalRequest.close();
              this.smsPromptService.openModal().result.then(() => {
                this.openSuccessModal();
              });
            } else {
              this.openSuccessModal();
            }
          } else {
            this.modalRequest.close();
            this.modalService.open(this.rewardFailed, {
              centered: true,
              windowClass: 'general-modal',
              modalDialogClass: 'rewards-modal'
            });
          }
        },
        error => {
          this.isLoading = false;
          this.modalRequest.close();
          this.modalService.open(this.rewardFailed, {
            centered: true,
            windowClass: 'general-modal',
            modalDialogClass: 'rewards-modal'
          });
        }
      );
    }
  }

  openSuccessModal(): void {
    this.modalRequest.close();
    this.modalSuccess = this.modalService.open(this.rewardSuccess, {
      centered: true,
      windowClass: 'general-modal',
      modalDialogClass: 'rewards-modal'
    });
  }

  onCloseSuccessModal(): void {
    this.modalSuccess.close();
  }

  redirectToStatus(): void {
    this.router.navigate(['/rewards/status']);
    this.onCloseSuccessModal();
  }

  setModalValue(event, rewardDialog, trackReward, i) {
    event.preventDefault();
    this.selectCard.emit(trackReward);
    this.rewardDialog = rewardDialog;
    this.trackReward = trackReward;
    this.index = i;
  }
}
