import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SmsPromptModalComponent } from '../components/sms-promt-modal/sms-prompt-modal.component';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { SMS_PROMPT_MODAL_SEEN_DETAILS } from '../components/sms-promt-modal/sms-prompt-modal-keys';
import * as moment from 'moment/moment';
import { switchMap, tap } from 'rxjs/operators';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { Observable } from 'rxjs';

const SMS_OPT_IN = 'sms-opt-in';

@Injectable({
  providedIn: 'root'
})
export class SmsPromptService {
  isOptIn = false;
  currentUserId: string;
  smsOptInLegalId: string;

  constructor(
    private ngbModal: NgbModal,
    private sdk: SuzySdkService,
    private auth: AuthenticationService,
    private launchDarkly: LaunchDarklyService
  ) {
    this.auth
      .ensureUser(true)
      .pipe(
        tap(user => {
          this.currentUserId = user.user_id;
        }),
        switchMap(() => this.sdk.ProtocolEngage.getLegalAvailable(SMS_OPT_IN))
      )
      .subscribe((response: any) => {
        if (response.success) {
          if (response.items.length > 0) {
            this.smsOptInLegalId = response.items[0].legal_id;
            this.isOptIn = true;
          }
        }
      });
  }

  saveModalSeenDetails(): void {
    const modalData = {
      seen_time: moment().valueOf(),
      user_id: this.currentUserId
    };
    localStorage.setItem(
      SMS_PROMPT_MODAL_SEEN_DETAILS,
      JSON.stringify(modalData)
    );
  }

  openModal(): NgbModalRef {
    this.saveModalSeenDetails();
    return this.ngbModal.open(SmsPromptModalComponent, {
      centered: true,
      windowClass: 'streak-modal'
    });
  }

  get isGetNotifiedModalSeen(): boolean {
    const dayInMilliseconds = 24 * 60 * 60 * 1000;
    const isFlagOn = this.launchDarkly.getRW80And104ShowSmsPromptsToUsers();

    if (!isFlagOn || !this.isOptIn) {
      return true;
    }

    const modalSeenDetails = localStorage.getItem(
      SMS_PROMPT_MODAL_SEEN_DETAILS
    );
    if (modalSeenDetails) {
      const { user_id, seen_time } = JSON.parse(modalSeenDetails);
      return !(
        this.currentUserId !== user_id ||
        (this.currentUserId === user_id &&
          moment().valueOf() - seen_time > dayInMilliseconds)
      );
    }

    return false;
  }

  optIn(): Observable<boolean> {
    return this.sdk.ProtocolEngage.optIn(this.smsOptInLegalId);
  }
}
